import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const articleList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'article/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get article list err : ', err)

      return {}
    })

  return response
}
const articleTagList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'article/tagslist',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get article tag list err : ', err)

      return {}
    })

  return response
}
const articleAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'article',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add article list err : ', err)

      return {}
    })

  return response
}
const articleTagUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'article/tags',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update article tag err : ', err)

      return {}
    })

  return response
}
const articleTagRemove = async param => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'article/tags',
      param,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('remove article tag err : ', err)

      return {}
    })

  return response
}
const articleUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'article/edit',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update article err : ', err)

      return {}
    })

  return response
}

const articleRemove = async param => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'article',
      param,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('remove article err : ', err)

      return {}
    })

  return response
}
export default {
  articleList, articleTagList, articleAdd, articleTagUpdate, articleTagRemove, articleUpdate, articleRemove,
}
