<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t("ManageArticle") }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-2 d-none d-md-block"
          @click="isAddNewContent = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>  {{ $t("addContent_review") }}
        </v-btn>
        <v-btn
          color="primary"
          class="me-2 d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewContent = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn
          color="info"
          class="d-none d-md-block"
          @click="isShowTag = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiTagOutline }}
          </v-icon> {{ $t('tag') }}
        </v-btn>
        <v-btn
          color="info"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="isShowTag = true"
        >
          <v-icon>
            {{ icons.mdiTagOutline }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model.trim="statusSelected"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.articleshop_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.articleshop_thumbnail`]="{ item }">
          <v-img
            :src="item.articleshop_thumbnail"
            width="50px"
            height="50px"
            class="rounded-lg elevation-1 my-2"
          ></v-img>
        </template>
        <template v-slot:[`item.articleshop_type_id`]="{ item }">
          {{ item.articleshop_type_id == 1 ? $t("review") : $t("article") }}
        </template>
        <template v-slot:[`item.articleshop_create`]="{ item }">
          <ConverseDate :date="item.articleshop_create" />
        </template>
        <template v-slot:[`item.content_status_id`]="{ item }">
          {{
            item.content_status_id == 1
              ? $t("open")
              : item.content_status_id == 2
                ? $t("close")
                : $t("set_time")
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item; isEditContent = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span class="set-font-kanit">{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="contentId = item.articleshop_id;isDeleteContent = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span class="set-font-kanit">{{ $t("delete") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <AddNewContent
      v-model="isAddNewContent"
      @onAdd="fetchDataTable()"
    />
    <Tags
      v-model="isShowTag"
    />
    <EditContent
      v-model="isEditContent"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable()"
    />
    <v-dialog
      v-model="isDeleteContent"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="error"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ $t("confirmDeleteContentReview") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="removeLoading"
            :disabled="removeLoading"
            @click="removeContent"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDeleteContent = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPencilOutline, mdiDeleteOutline, mdiPlus, mdiTagOutline,
  mdiAlertOutline,
} from '@mdi/js'
import useReviewContentManagement from './useReviewContentManagement'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import AddNewContent from './AddNewContent.vue'
import Tags from './Tags.vue'
import EditContent from './EditContent.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
    ConverseDate,
    AddNewContent,
    EditContent,
    Tags,
  },
  setup() {
    return {
      ...useReviewContentManagement(),
      icons: {
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
        mdiTagOutline,
        mdiAlertOutline,
      },
    }
  },

}
</script>
