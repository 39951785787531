import article from '@/api/WebsiteManageMent/article'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useReviewContentManagement() {
  const searchtext = ref('')
  const statusList = ref([
    {
      value: '',
      th: 'ทั้งหมด',
      en: 'All',
    },
    {
      value: '1',
      th: 'เปิด',
      en: 'All',
    },
    {
      value: '2',
      th: 'ปิด',
      en: 'Close',
    },
    {
      value: '3',
      th: 'ตั้งเวลา',
      en: 'Time set',
    },
  ])

  const statusSelected = ref('')

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const isAddNewContent = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'center',
      value: 'articleshop_id',
      width: 30,
    },
    { text: i18n.t('picture'), value: 'articleshop_thumbnail' },
    { text: i18n.t('name'), value: 'articleshop_title' },
    { text: i18n.t('type'), value: 'articleshop_type_id' },
    { text: i18n.t('by'), value: 'user_fullname' },
    { text: i18n.t('add_on'), value: 'articleshop_create' },
    { text: i18n.t('status'), value: 'content_status_id' },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 120,
      align: 'center',
    },
  ])

  const isShowTag = ref(false)
  const isEditContent = ref(false)
  const dataEdit = ref({})

  const isDeleteContent = ref(false)
  const contentId = ref('')
  const removeLoading = ref(false)

  const removeContent = () => {
    removeLoading.value = true
    article.articleRemove(contentId.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      fetchDataTable('delete')
      isDeleteContent.value = false
      removeLoading.value = false
    })
  }

  const fetchDataTable = e => {
    loading.value = true
    article
      .articleList({
        searchtext: searchtext.value,
        content_status_id: statusSelected.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        dataTableList.value = data
        totalPage.value = count_of_page
        totalDataTableList.value = count
        segmentId.value = segment
        if (e == 'add') {
          options.value.page = count_of_page
        } else if (e == 'delete') {
          if (+options.value.page >= +count_of_page) {
            options.value.page = count_of_page
          }
        }
        loading.value = false
      })
      .catch(err => {
        console.log('get article list err : ', err)
      })
  }

  watch([searchtext, statusSelected, options], (newvalue, oldvalue) => {
    if (newvalue[0] != oldvalue[0] || newvalue[1] != oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    totalPage,
    searchtext,
    statusList,
    statusSelected,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    columns,
    isAddNewContent,
    isShowTag,
    isEditContent,
    dataEdit,

    isDeleteContent,
    contentId,
    removeLoading,
    removeContent,
    fetchDataTable,
  }
}
