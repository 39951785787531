<template>
  <v-dialog
    v-model="isEditContent"
    persistent
    max-width="1100"
  >
    <v-card>
      <v-card-title>
        {{ $t("edit_article") }}
      </v-card-title>
      <v-form
        ref="formEditContent"
      >
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="articleshop_title"
                :label="$t('name')"
                outlined
                :rules="[required]"
                dense
              ></v-text-field>
              <v-textarea
                v-model="articleshop_excerpt"
                rows="2"
                hide-details
                :rules="[required]"
                :label="$t('brief_content')"
                outlined
                dense
                class="mb-3"
              ></v-textarea>
              <label>{{ $t("detail") }}</label>
              <quill-editor
                ref="myTextEditor"
                v-model="articleshop_text"
                class="mb-5"
                :options="editorOption"
              >
              </quill-editor>
              <v-text-field
                v-model="articleshop_keyword"
                :label="$t('keyword_comma')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="articleshop_tags"
                dense
                outlined
                hide-details
                :label="`${$t('tag')} (${$t('pressEnterForAddTag')})`"
                @keypress.enter="addTag"
              >
              </v-text-field>
              <v-chip
                v-for="(item, index) in tagsList"
                :key="index"
                close
                small
                color="primary"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text me-2 mt-2`"
                @click:close="removeTag(index)"
              >
                {{ item }}
              </v-chip>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="articleshop_type_id"
                :items="type_list"
                :item-text="$i18n.locale"
                item-value="type_id"
                outlined
                :rules="[required]"
                dense
                :label="$t('choose_type')"
              ></v-select>
              <v-select
                v-model="content_status_id"
                :items="status_list"
                :item-text="$i18n.locale"
                :rules="[required]"
                item-value="value"
                outlined
                dense
                :label="$t('status')"
              ></v-select>
              <v-datetime-picker
                v-if="content_status_id == 3"
                v-model="articleshop_start"
                :rules="[required]"
                :ok-text="$t('save')"
                :clear-text="$t('cancel')"
                :text-field-props="{
                  dense: true,
                  outlined: true,
                  flat:true
                }"
                :date-picker-props="{
                  locale: $i18n.locale,
                  elevation:0
                }"
                :time-picker-props="{
                  locale: $i18n.locale,
                  elevation:0,
                  format:'24hr'
                }"
                :label="$t('start_showing')"
              >
                <template slot="dateIcon">
                  <v-icon>{{ icons.mdiCalendarToday }}</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>{{ icons.mdiClockOutline }}</v-icon>
                </template>
              </v-datetime-picker>
              <v-datetime-picker
                v-if="content_status_id == 3"
                v-model="articleshop_end"
                :ok-text="$t('save')"
                :rules="[required]"
                :clear-text="$t('cancel')"
                :text-field-props="{
                  dense: true,
                  outlined: true,
                }"
                :date-picker-props="{
                  locale: $i18n.locale,
                }"
                :time-picker-props="{
                  locale: $i18n.locale,
                  format:'24hr'
                }"
                :label="$t('end_of_show')"
              >
                <template slot="dateIcon">
                  <v-icon>{{ icons.mdiCalendarToday }}</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>{{ icons.mdiClockOutline }}</v-icon>
                </template>
              </v-datetime-picker>
              <label>{{ $t("picture") }}</label>
              <v-img
                class="rounded-lg elevation-3"
                :src="url"
                height="400px"
              ></v-img>
              <v-btn
                class="rounded-md elevation-3 mt-3"
                color="primary"
                block
                @click="$refs.selectImage.click()"
              >
                {{ $t("UploadImage") }}
              </v-btn>
              <input
                ref="selectImage"
                type="file"
                style="display: none"
                @change="previewFiles"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="updateContent"
          >
            {{ $t("save") }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:isEditContent',false)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { mdiCloudUploadOutline, mdiCalendarToday, mdiClockOutline } from '@mdi/js'
import article from '@/api/WebsiteManageMent/article'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  components: {
    quillEditor,
  },
  model: {
    prop: 'isEditContent',
    event: 'update:isEditContent',
  },
  props: {
    isEditContent: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const url = ref('https://www.apsth.com/appv2/assets/upload/article/none.png')
    const image_review = ref('')
    const articleshop_id = ref('')
    const articleshop_excerpt = ref('')
    const articleshop_text = ref('')
    const articleshop_title = ref('')
    const articleshop_keyword = ref('')
    const articleshop_start = ref('')
    const articleshop_end = ref('')
    const content_status_id = ref('')
    const articleshop_type_id = ref('')
    const articleshop_view = ref('')
    const articleshop_tags = ref('')
    const tags = ref([])
    const status_list = ref([
      {
        value: '1',
        th: 'เปิด',
        en: 'All',
      },
      {
        value: '2',
        th: 'ปิด',
        en: 'Close',
      },
      {
        value: '3',
        th: 'ตั้งเวลา',
        en: 'Time set',
      },
    ])
    const type_list = ref([
      { type_id: 2, en: 'Article', th: 'บทความ' },
      { type_id: 1, en: 'Review', th: 'รีวิว' },
    ])
    const dialog = ref(false)
    const product_detail = ref('')
    const tagsList = ref([])
    const editorOption = ref({
      placeholder: '',
    })
    const formEditContent = ref(null)
    const loading = ref(false)

    const updateContent = () => {
      const isFormValid = formEditContent.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('articleshop_id', articleshop_id.value)
      formData.append('articleshop_thumbnail', image_review.value)
      formData.append('articleshop_title', articleshop_title.value)
      formData.append('articleshop_excerpt', articleshop_excerpt.value)
      formData.append('articleshop_text', articleshop_text.value)
      formData.append('articleshop_keyword', articleshop_keyword.value)
      formData.append('articleshop_start', articleshop_start.value)
      formData.append('articleshop_end', articleshop_end.value)
      formData.append('content_status_id', content_status_id.value)
      formData.append('articleshop_type_id', articleshop_type_id.value)
      formData.append('articleshop_tags', tagsList.value)
      article.articleUpdate(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isEditContent', false)
        emit('onUpdate')
        loading.value = false
      })
    }

    const addTag = () => {
      tagsList.value.push(articleshop_tags.value)
      articleshop_tags.value = ''
    }

    const previewFiles = event => {
      image_review.value = event.target.files[event.target.files.length - 1]
      url.value = URL.createObjectURL(
        event.target.files[event.target.files.length - 1],
      )
    }

    const removeTag = index => {
      tagsList.value.splice(index, 1)
    }

    const mapData = value => {
      articleshop_title.value = value.articleshop_title
      articleshop_excerpt.value = value.articleshop_excerpt
      articleshop_text.value = value.articleshop_text
      articleshop_keyword.value = value.articleshop_keyword
      articleshop_start.value = value.articleshop_start
      articleshop_end.value = value.articleshop_end
      articleshop_type_id.value = +value.articleshop_type_id
      tagsList.value = !value.tags
        ? []
        : value.tags.map(item => item.articleshop_tag_name)
      articleshop_id.value = value.articleshop_id
      content_status_id.value = value.content_status_id
      url.value = value.articleshop_thumbnail
    }

    watch(() => props.isEditContent, value => {
      if (value) {
        mapData(props.dataEdit)
      }
    })

    return {
      url,
      required,
      image_review,
      articleshop_excerpt,
      articleshop_text,
      articleshop_title,
      articleshop_keyword,
      articleshop_start,
      articleshop_end,
      content_status_id,
      articleshop_type_id,
      articleshop_view,
      articleshop_tags,
      tags,
      status_list,
      type_list,
      dialog,
      product_detail,
      editorOption,
      formEditContent,
      tagsList,
      loading,
      previewFiles,
      updateContent,
      addTag,
      removeTag,
      icons: {
        mdiCloudUploadOutline,
        mdiCalendarToday,
        mdiClockOutline,
      },
    }
  },
}
</script>

<style lang="scss">

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 240px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

</style>
