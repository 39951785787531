<template>
  <div>
    <v-dialog
      v-model="isShowTag"
      max-width="1200"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t('tag') }}</v-card-title>
        <v-row class="mx-2 mb-2">
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-text-field
              v-model="searchtext"
              dense
              outlined
              hide-details
              :label="$t('search')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="columns"
          :items="dataTableList"
          :options.sync="options"
          :server-items-length="totalDataTableList"
          :footer-props="footer"
          :loading="loading"
          disable-sort
          :loading-text="$t('data_loading')"
          :no-data-text="$t('no_information')"
        >
          <template v-slot:[`item.articleshop_tag_id`]="{ index }">
            {{ segmentId + index + 1 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click="dataEdit =JSON.parse(JSON.stringify(item)) ; isEdit = true"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  :disabled="item.count_tag > 0"
                  v-bind="attrs"
                  @click="tagId = item.articleshop_tag_id ; isDelete = true"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:isShowTag',false)"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isEdit"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('editTag') }}
        </v-card-title>
        <v-form
          ref="formEditTag"
          @submit.prevent="updateTag"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEdit.articleshop_tag_name"
              dense
              :rules="[required]"
              outlined
              :label="$t('tag')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="updateLoading"
              :disabled="updateLoading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="isEdit = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDelete"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon class="me-1 error--text">
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ $t('confirmDeleteTag') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="removeLoading"
            :disabled="removeLoading"
            @click="removeTag"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDelete = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPencilOutline, mdiDeleteOutline, mdiAlertOutline } from '@mdi/js'
import article from '@/api/WebsiteManageMent/article'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  model: {
    prop: 'isShowTag',
    event: 'update:isShowTag',
  },
  props: {
    isShowTag: Boolean,
  },
  setup(props) {
    const searchtext = ref('')
    const dataEdit = ref({})
    const dataTableList = ref([])
    const totalDataTableList = ref(0)
    const segmentId = ref(0)
    const options = ref({})
    const loading = ref(false)
    const isEdit = ref(false)
    const isDelete = ref(false)
    const removeLoading = ref(false)
    const tagId = ref('')
    const updateLoading = ref(false)
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const columns = ref([
      {
        text: '#',
        align: 'center',
        value: 'articleshop_tag_id',
        width: 20,
      },
      {
        text: i18n.t('tag'),
        value: 'articleshop_tag_name',
      },
      {
        text: i18n.t('amount'),
        value: 'count_tag',
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        align: 'center',
      },
    ])

    const formEditTag = ref(null)
    const getTagList = () => {
      loading.value = true
      article.articleTagList({
        searchtext: searchtext.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }).then((res => {
        const { data, count, segment } = res
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      }))
    }

    const updateTag = () => {
      const isFormValid = formEditTag.value.validate()
      if (!isFormValid) return
      updateLoading.value = true
      article.articleTagUpdate(dataEdit.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getTagList()
        isEdit.value = false
        updateLoading.value = false
      })
    }

    const removeTag = () => {
      removeLoading.value = true
      article.articleTagRemove(tagId.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getTagList()
        isDelete.value = false
        removeLoading.value = true
      })
    }

    watch(() => props.isShowTag, value => {
      if (value) {
        getTagList()
      }
    })

    watch([searchtext, options], (newvalue, oldvalue) => {
      if (newvalue[0] !== oldvalue[0]) {
        options.value.page = 1
      }
      getTagList()
    })

    return {
      searchtext,
      dataTableList,
      totalDataTableList,
      segmentId,
      options,
      loading,
      footer,
      columns,
      dataEdit,
      isEdit,
      formEditTag,
      required,
      updateLoading,
      isDelete,
      removeLoading,
      tagId,
      getTagList,
      updateTag,
      removeTag,
      icons: {
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiAlertOutline,
      },
    }
  },
}
</script>
